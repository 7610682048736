import React from 'react';
import { useBreakpointValue, useTheme } from '@aws-amplify/ui-react';
import Container from '@components/container';
import styled from 'styled-components';
import PrimaryButton from '@components/buttons/primary';
import { media } from '@theme/mediaQueries';
import HtmlText from '@components/text/htmlText';

/**
 * @typedef {import("@prismicio/client").Content.FullWidthPromoSlice} FullWidthPromoSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FullWidthPromoSlice>} FullWidthPromoProps
 * @param { FullWidthPromoProps }
 */

interface bgProps {
  $backgroundImage: any;
}

const BackgroundImageStyles = styled.div<bgProps>`
  position: relative;
  width: 100%;
  min-height: 333px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding: 30px 0;
  background-image: url(${(props) => props.$backgroundImage});
  overflow: hidden;

  .amplify-heading {
    margin-top: 0;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 28px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      ${(props) => props?.theme.tokens.colors.primary.value} -10.66%,
      rgba(35, 35, 35, 0) 100%
    );
  }

  ${(props) =>
    media('medium')`
    min-height: 170px;

    .amplify-heading {
      margin-bottom: 19px;
    }
  `}
`;

const ContentStyles = styled.div`
  position: relative;
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
  z-index: 2;
  color: ${(props) => props?.theme.tokens.colors.white.value};

  > div {
    max-width: 600px;
  }

  .amplify-heading {
    color: ${(props) => props?.theme.tokens.colors.white.value};
  }

  ${media('medium')`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
    }
  `};
`;

const PromoIconStyles = styled.div`
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    right: -120px;
    bottom: 30px;
    width: 220px;
    height: 84px;
    background-image: url('/../../images/zap.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  ${media('medium')`
    &::after {
      bottom: 37px;
    }
  `};
`;

const ButtonContainerStyles = styled.div`
  ${media('medium')`
    margin-right: 40px;
  `};
`;

const FullWidthPromo = ({ slice }: any) => {
  const theme = useTheme(),
    backgroundSliceImage = slice.primary.background_image.url,
    backgroundDesktopSliceImage = slice.primary.background_image.desktop?.url || slice.primary.background_image.url;

  const variation = useBreakpointValue({
    base: backgroundSliceImage,
    medium: backgroundDesktopSliceImage
  });

  return (
    <section className="wwi-relative">
      <BackgroundImageStyles
        theme={theme}
        $backgroundImage={variation || 'default'}
      >
        <Container>
          <ContentStyles theme={theme}>
            <div>
              {slice.primary.title ? (
                <HtmlText field={slice.primary.title} />
              ) : (
                <h2>Template slice, update me!</h2>
              )}
              {slice.primary.description ? (
                <HtmlText field={slice.primary.description} />
              ) : (
                <p>start by editing this slice from inside Slice Machine!</p>
              )}
            </div>
            <ButtonContainerStyles>
              {slice.primary.link && (
                <PrimaryButton hasIcon="true" field={slice.primary.link}>
                  {slice.primary.link_text}
                </PrimaryButton>
              )}
            </ButtonContainerStyles>
          </ContentStyles>
        </Container>
        <PromoIconStyles></PromoIconStyles>
      </BackgroundImageStyles>
    </section>
  );
};

export default FullWidthPromo;
