import { Flex, View, useTheme } from '@aws-amplify/ui-react';
import styled, { css } from 'styled-components';
import { Rocks, Download } from '@components/utilities/icons';
import HtmlTitle from '@components/title/htmlTitle';
import HtmlText from '@components/text/htmlText';
import { media } from '@theme/mediaQueries';
import LocalLink from '@components/global/localLink';

interface CardsProps {
  cardHighlight?: string;
  cardTitle: string;
  cardText?: string;
  cardTag?: string;
  cardLink: any;
  cardDataLink?: any; //Used for document content type.
  cardImage?: any;
  variation?: string;
  count?: number;
}

interface CardImageProps {
  readonly $backgroundImage: any;
  readonly variation: string | undefined;
}

interface HighlightProps {
  readonly variation: string | undefined;
}

interface CardBodyProps {
  readonly variation: string | undefined;
}

interface CardStylesProps {
  readonly variation: string | undefined;
  readonly count: number | undefined;
  readonly theme: any;
}

const CardStyles = styled.div<CardStylesProps>`
  display: flex;
  padding: 0;
  position: relative;
  z-index: 1;
  flex: 1 0 100%;

  .card-wrapper {
    width: 100%;
    display: block;
    transition: 0.3s ease-in-out background-color;
    background-color: ${(props: any) =>
      props?.theme.tokens.colors.background.primary.value};

    &:hover {
      background-color: ${(props: any) =>
        props?.theme.tokens.colors.background.quaternary.value};

      .amplify-heading {
        text-decoration: underline;
      }

      .wwi-icon--rocks path {
        fill: ${(props: any) =>
          props?.theme.tokens.colors.background.quaternary.value};
      }
    }
  }

  ${media('medium')`
    flex: 1 0 30%;
    max-width: 50%;

    ${({ count, variation }: any) =>
      variation == 'multiPromo' &&
      count == 4 &&
      css`
        flex: 1 0 48%;
      `}
  `}
`;

const CardImageStyles = styled.div<CardImageProps>`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: ${(props: any) =>
    props.variation == 'default' ? '257px' : '228px'};
  background-image: url(${(props: any) => props.$backgroundImage.url});
`;

const CardHighlightStyles = styled.div<HighlightProps>`
  position: relative;
  background-color: ${(props: any) =>
    props.variation == 'default'
      ? props?.theme.tokens.colors.primary.value
      : props?.theme.tokens.colors.buttonPrimary.value};
  color: ${(props: any) =>
    props.variation == 'default'
      ? props?.theme.tokens.colors.white.value
      : props?.theme.tokens.colors.primary.value};
  overflow: hidden;
  text-transform: capitalize;

  ${({ variation }: any) =>
    variation != 'document' &&
    `
    top: -39px;
  `}

  span {
    margin-right: 8px;
    display: inline-block;
  }

  .wwi-card-highlight__icon {
    max-width: 75px;
    position: absolute;
    bottom: -11px;
    right: -10px;
    z-index: 0;
  }

  .wwi-icon {
    &--rocks {
      width: 100%;
      vertical-align: middle;
    }
  }

  p {
    position: relative;
    padding: 15px 25px;
    margin: 0;
  }
`;

const CardBodyStyles = styled.div<CardBodyProps>`
  padding: 20px;

  ${({ variation }: any) =>
    variation != 'document' &&
    `
    padding-top: 0;
  `}

  ${({ variation }: any) =>
    variation == 'multiPromo' &&
    css`
      padding-top: 50px;
      padding-bottom: 41px;

      ${media('medium')`
        padding: 35px 32px 43px;
      `}
    `}

  .amplify-heading {
    font-size: 1.375rem;
  }

  ${media('medium')`
    .amplify-heading {
      font-size: 1.125rem;
    }
  `};
`;

const Cards = ({
  cardLink,
  cardDataLink,
  cardImage,
  cardHighlight,
  cardTitle,
  cardText,
  cardTag,
  variation,
  count,
}: CardsProps) => {
  const theme = useTheme();

  let size = 0;
  let fileType = null;
  if (variation == 'document' && cardDataLink) {
    // Set size as MB
    size = parseFloat((cardDataLink.size / 1024 / 1024).toFixed(2));
    // Get file type from extension from name
    fileType = cardDataLink.name.split('.').pop();
  }

  return (
    <CardStyles theme={theme} count={count} variation={variation}>
      <LocalLink link={cardLink} className="card-wrapper">
        {cardImage?.url && (
          <CardImageStyles
            variation={variation}
            $backgroundImage={cardImage}
          ></CardImageStyles>
        )}
        <Flex direction="column" alignItems="flex-start" gap="0">
          {cardHighlight && (
            <CardHighlightStyles
              className="wwi-card-highlight"
              theme={theme}
              variation={variation}
            >
              <>
                {variation == 'document' ? (
                  <p>
                    <span>
                      <Download />
                    </span>{' '}
                    {fileType?.toUpperCase()}
                  </p>
                ) : (
                  <p>
                    {variation == 'product' && '£'}
                    {cardHighlight}
                  </p>
                )}
                <View as="div" className="wwi-card-highlight__icon">
                  <Rocks />
                </View>
              </>
            </CardHighlightStyles>
          )}
          <CardBodyStyles variation={variation}>
            <HtmlTitle type="heading3">{cardTitle}</HtmlTitle>
            <p>{variation == 'document' ? `${size}mb` : cardTag}</p>
            <HtmlText field={cardText} />
          </CardBodyStyles>
        </Flex>
      </LocalLink>
    </CardStyles>
  );
};

export default Cards;
