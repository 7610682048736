import React from 'react';
import { useTheme } from '@aws-amplify/ui-react';
import Container from '@components/container';
import styled, { css } from 'styled-components';
import { media } from '@theme/mediaQueries';
import HtmlText from '@components/text/htmlText';
import { PrismicRichText } from '@prismicio/react';

/**
 * @typedef {import("@prismicio/client").Content.EmbedSlice} EmbedSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<EmbedSlice>} EmbedProps
 * @param { EmbedProps }
 */

interface SectionProps {
  readonly $backgroundColor: string;
  readonly variation: string;
  readonly $sliceBackground: string;
}

interface InnerProps {
  readonly variation: string;
  readonly $customIframeHeight: number;
}

const SectionStyles = styled.section<SectionProps>`
  padding-top: 61px;
  padding-bottom: 72px;
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.color};

  .amplify-heading {
    color: ${(props) => props.color};
  }

  // Form styles
  #mc_embed_signup {
    max-width: 100%;
    background-color: ${(props) => props.$backgroundColor};
    color: ${(props) => props.color};

    #mc-embedded-subscribe-form div.mce_inline_error {
      background-color: transparent;
      color: ${(props) => props?.theme.tokens.colors.background.error.value};
      font-family: var(--fonts-default-title);
      letter-spacing: 0.02em;
    }

    div {
      #mce-responses {
        margin: 0;
        padding: 0;
      }

      .response {
        margin: 0;
        padding: 2rem 0;
      }
    }

    .brandingLogo {
      display: none;
    }

    .helper_text {
      color: ${(props) => props.color};
      background-color: transparent;
    }

    form {
      margin: 0;

      .mc-field-group {
        label {
          font-family: var(--fonts-default-html);
          margin-bottom: 10px;
          font-size: var(--font-size-label);
        }

        input {
          border-radius: 3px;
          padding: 16px 0;
          border: 1px solid
            ${(props) => props?.theme.tokens.colors.borderMain.value};

          &::placeholder {
            color: rgba(
              ${(props) => props?.theme.tokens.colors.borderMain.value},
              0.5
            );
          }
        }

        select {
          padding: 16px 0;
        }
      }

      input[type='checkbox'],
      input[type='radio'] {
        // hide the input as using pseudo elem
        opacity: 0;
      }

      input[type='radio'] {
        + label {
          &::before,
          &::after {
            border-radius: 100%;
          }
        }
      }

      input[type='checkbox'],
      input[type='radio'] {
        width: 30px;
        height: 30px;

        &:focus {
          + label::before {
            outline: -webkit-focus-ring-color auto 1px;
          }
        }

        + label {
          vertical-align: text-bottom;
          margin-left: 15px;
          margin-bottom: 7px;
          display: inline-block;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: -6px;
            left: -60px;
            width: 30px;
            height: 30px;
            background-color: white;
            border: 1px solid
              ${(props) => props?.theme.tokens.colors.borderMain.value};
          }
        }

        &:checked {
          + label {
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: -54px;
              width: 20px;
              height: 20px;
              background-color: ${(props) =>
                props?.theme.tokens.colors.primary.value};
            }
          }
        }
      }

      .mc-address-fields-group {
        display: block;
        width: 100%;
        max-width: 200px;

        .mc-field-group {
          padding-bottom: 18px;
        }
      }

      .gdprRequired {
        padding: 16px 0;
        margin: 0;

        input {
          &:focus {
            + span::before {
              outline: -webkit-focus-ring-color auto 1px;
            }
          }

          + span {
            position: relative;
            vertical-align: text-bottom;
            margin-left: 15px;
            margin-bottom: 7px;
            display: inline-block;

            &::before {
              content: '';
              position: absolute;
              top: -6px;
              left: -60px;
              width: 30px;
              height: 30px;
              background-color: white;
              border: 1px solid
                ${(props) => props?.theme.tokens.colors.borderMain.value};
            }
          }

          &:checked {
            + span {
              &::after {
                content: '';
                position: absolute;
                top: 0;
                left: -54px;
                width: 20px;
                height: 20px;
                background-color: ${(props) =>
                  props?.theme.tokens.colors.primary.value};
              }
            }
          }
        }
      }

      .datefield {
        input[type='text'] {
          min-width: 50px;
        }

        .small-meta {
          display: block;
        }
      }

      .button {
        font-family: var(--fonts-default-html);
        border-radius: 3px;
        background-color: ${(props) =>
          props?.$sliceBackground == 'dark (alternative)'
            ? props?.theme.tokens.colors.buttonPrimary.value
            : props?.theme.tokens.colors.primary.value};
        color: ${(props) =>
          props?.$sliceBackground == 'dark (alternative)'
            ? props?.theme.tokens.colors.black.value
            : props?.theme.tokens.colors.white.value};
        font-size: 1rem;
        height: 50px;
        border: none;
        display: inline-block;

        &:hover {
          background-color: ${(props) =>
            props?.theme.tokens.colors.white.value};
          color: ${(props) => props?.theme.tokens.colors.black.value};
        }
      }
    }
  }

  ${media('medium')`
    padding-top: 57px;
    padding-bottom: 110px;
  `};

  ${({ variation }: any) =>
    variation == 'videoOnTop' &&
    css`
      padding-bottom: 28px;

      ${media('medium')`
        padding-top: 71px;
        padding-bottom: 81px;
      `};
    `}

  ${({ variation }: any) =>
    variation == 'sideBySide' &&
    css`
      padding-top: 77px;
      padding-bottom: 34px;

      ${media('medium')`
        padding-top: 100px;
        padding-bottom: 122px;
      `};
    `}
`;

const InnerSectionStyles = styled.div<InnerProps>`
  iframe {
    width: 100%;
    height: 240px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 42px;
  }

  ${media('medium')`
    iframe {
      height: ${(props: { $customIframeHeight: any }) =>
        props.$customIframeHeight ? props.$customIframeHeight + 'px' : '600px'};
    }

    h2 {
      margin-bottom: 44px;
    }
  `};

  ${({ variation }: any) =>
    variation == 'sideBySide' &&
    css`
      iframe {
        height: 437px;
      }

      .amplify-heading {
        margin-bottom: 16px;
      }

      p {
        margin-top: 0;
      }

      ${media('medium')`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10rem;

        .amplify-heading {
          margin-bottom: 10px;
        }

        .wwi-embed-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        iframe {
          height: 467px;
          max-width: 364px;
        }
      `};

      ${media('xl')`
        gap: 17.25rem;
      `};
    `}

  ${({ variation }: any) =>
    variation == 'videoOnTop' &&
    css`
      iframe {
        height: 299px;
      }

      ${media('medium')`
        iframe {
          height: 453px;
        }
      `};
    `}
`;

const Embed = ({ slice }: any) => {
  const theme = useTheme();

  let backgroundColor = theme.tokens.colors.background.primary.value,
    color = theme.tokens.colors.primary.value,
    variation = slice.variation,
    sliceBackground = slice.primary?.background_colour?.toLowerCase(),
    customIframeHeight = slice.primary?.custom_iframe_height;

  switch (sliceBackground) {
    case 'light':
      backgroundColor = theme.tokens.colors.background.secondary.value;
      break;

    case 'white':
      backgroundColor = theme.tokens.colors.background.primary.value;
      break;

    // Default to dark (alternative)
    default:
      backgroundColor = theme.tokens.colors.background.tertiary.value;
      color = theme.tokens.colors.white.value;
      break;
  }

  return (
    <SectionStyles
      $backgroundColor={backgroundColor}
      color={color}
      variation={variation}
      theme={theme}
      $sliceBackground={sliceBackground}
    >
      <Container>
        <InnerSectionStyles
          variation={variation}
          $customIframeHeight={customIframeHeight}
        >
          <div className="wwi-embed-content">
            {slice.primary.title && <HtmlText field={slice.primary.title} />}
            {variation !== 'videoOnTop' && (
              <>
                {slice.primary.description && (
                  <HtmlText field={slice.primary.description} />
                )}
              </>
            )}
          </div>
          <PrismicRichText
            field={slice.primary.embed}
            components={{
              preformatted: ({ text }) => (
                <>
                  {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
                </>
              ),
            }}
          />
          {variation === 'videoOnTop' && (
            <>
              {slice.primary.description && (
                <HtmlText field={slice.primary.description} />
              )}
            </>
          )}
        </InnerSectionStyles>
      </Container>
    </SectionStyles>
  );
};

export default Embed;
