import React from 'react';
import HtmlText from '@components/text/htmlText';
import Container from '@components/container';
import styled from 'styled-components';
import { useTheme, Grid, View, Flex } from '@aws-amplify/ui-react';
import { DefaultImage } from '@components/image/defaultImage';
import SecondaryButton from '@components/buttons/secondary';
import { media } from '@theme/mediaQueries';

/**
 * @typedef {import("@prismicio/client").Content.TextAndImageSlice} TextAndImageSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TextAndImageSlice>} TextAndImageProps
 * @param { TextAndImageProps }
 */

interface SectionProps {
  readonly backgroundColor: string;
}

const SectionStyles = styled.section<SectionProps>`
  background-color: ${(props: any) => props.backgroundColor};
  padding: 81px 0 57px;
  overflow: hidden;

  .wwi-text-image {
    &__title {
      font-family: ${(props: any) => props.theme.tokens.fonts.default};
      font-weight: 700;
      font-size: var(--font-size-h4);
    }

    &__content {
      margin-top: 43px;
      position: relative;
      z-index: 1;

      a {
        margin-top: 40px;
      }
    }
  }

  ${media('medium')`
    padding: 121px 0 118px;

    .wwi-text-image {
      &__content {
        margin-top: 0;

        a {
          margin-top: 16px
        }
      }

      &__order {
        order: 1;
      }
    }
  `};
`;

const TextAndImage = ({ slice }: any) => {
  const isRight = slice.primary.alignment,
    theme = useTheme();

  let backgroundColor = theme.tokens.colors.background.primary.value;
  switch (slice.primary?.background_colour?.toLowerCase()) {
    case 'light':
      backgroundColor = theme.tokens.colors.background.secondary.value;
      break;

    case 'white':
      backgroundColor = theme.tokens.colors.background.primary.value;
      break;
  }

  return (
    <SectionStyles
      theme={theme}
      backgroundColor={backgroundColor}
      className="wwi-text-image"
    >
      <Container>
        <Grid
          columnGap="5rem"
          templateColumns={{ base: '1fr', medium: '1fr 1fr' }}
          templateRows="1fr"
        >
          <View
            as="div"
            className={`${'wwi-text-image__image'}${
              isRight ? ' wwi-text-image__order' : ''
            }`}
          >
            <DefaultImage
              src={slice.primary.image.url}
              alt={slice.primary.image.alt}
              width={slice.primary.image.dimensions.width}
              height={slice.primary.image.dimensions.height}
              copyright={slice.primary.image.copyright}
              position="bottom"
              type="accents-right"
            />
          </View>
          <Flex alignItems="center">
            <View as="div" className="wwi-text-image__content">
              {slice.primary.title ? (
                <HtmlText
                  customClass="wwi-text-image__title"
                  field={slice.primary.title}
                />
              ) : (
                ''
              )}
              {slice.primary.description ? (
                <HtmlText field={slice.primary.description} />
              ) : (
                ''
              )}
              {slice.primary.button_text ? (
                <SecondaryButton
                  hasIcon="true"
                  field={slice.primary.button_link}
                >
                  {slice.primary.button_text}
                </SecondaryButton>
              ) : (
                ''
              )}
            </View>
          </Flex>
        </Grid>
      </Container>
    </SectionStyles>
  );
};

export default TextAndImage;
