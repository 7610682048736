import React from 'react';
import { media } from '@theme/mediaQueries';
import styled from 'styled-components';
import { Flex, useTheme } from '@aws-amplify/ui-react';
import Container from '@components/container';
import HtmlText from '@components/text/htmlText';
import { AccentTwo } from '@components/utilities/icons';

/**
 * @typedef {import("@prismicio/client").Content.PullOutQuoteSlice} PullOutQuoteSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<PullOutQuoteSlice>} PullOutQuoteProps
 * @param { PullOutQuoteProps }
 */

interface SectionProps {
  readonly backgroundColor: string;
}

const SectionStyles = styled.section<SectionProps>`
  background-color: ${(props: any) => props.backgroundColor};
  padding: 81px 0;

  .wwi-quote {
    &__quote-text {
      margin-bottom: 33px;
      font-size: var(--font-size-h4);
    }

    &__name,
    &__job {
      font-family: var(--fonts-default-title);
      font-size: var(--font-size-label);
    }

    &__job {
      color: ${(props) => props?.theme.tokens.colors.borderMain.value};
    }
  }

  ${media('medium')`
    padding: 119px 0;

    .wwi-quote {
      &__quote-text {
        margin-bottom: 25px;
      }

      &__job {
        position: relative;
        &::before {
          content: "|";
          position: absolute;
          left: -12px;
          top: -1px;
          color: ${(props: any) => props.theme.tokens.colors.black.value};
        }    
      }
    }
  `}
`;

const QuoteStyles = styled.div`
  background-color: ${(props) =>
    props?.theme.tokens.colors.background.primary.value};
  padding: 115px 24px 48px;
  position: relative;

  ${media('medium')`
    padding: 92px 84px 64px;
  `}
`;

const IconStyles = styled.div`
  width: 104px;
  height: 91px;
  position: absolute;
  top: 24px;
  left: 0;

  ${media('medium')`
    width: 164px;
    height: 145px;
    top: -60px;
    left: 44px;
  `}
`;

const PullOutQuote = ({ slice }: any) => {
  const theme = useTheme();

  /* Functionality to change BG colour added but 
   * not added field in Prismic as unsure if it is a requirement yet
   * defaults to light grey which is in design
   */
  let backgroundColor = theme.tokens.colors.background.primary.value;
  switch (slice.primary?.background_colour?.toLowerCase()) {
    case 'light':
      backgroundColor = theme.tokens.colors.background.secondary.value;
      break;

    case 'white':
      backgroundColor = theme.tokens.colors.background.primary.value;
      break;

    case 'dark':
      backgroundColor = theme.tokens.colors.background.tertiary.value;
      break;

    default:
      backgroundColor = theme.tokens.colors.background.secondary.value;
      break;
  }
  return (
    <SectionStyles
      theme={theme}
      backgroundColor={backgroundColor}
      className="wwi-quote"
    >
      <Container>
        <QuoteStyles theme={theme}>
          <IconStyles>
            <AccentTwo />
          </IconStyles>
          {slice.primary.quote ? (
            <HtmlText
              customClass="wwi-quote__quote-text"
              field={slice.primary.quote}
            />
          ) : (
            <h2>Template slice, update me!</h2>
          )}
          <Flex
            direction={{ base: 'column', medium: 'row' }}
            gap={{ base: '0', medium: '16px' }}
            alignItems={{ medium: 'center' }}
          >
            {slice.primary.name ? (
              <p className="wwi-quote__name mt-0 mb-0">{slice.primary.name}</p>
            ) : (
              <p>start by editing this slice from inside Slice Machine!</p>
            )}
            {slice.primary.job_title ? (
              <p className="wwi-quote__job mt-0 mb-0">
                {slice.primary.job_title}
              </p>
            ) : (
              <p>start by editing this slice from inside Slice Machine!</p>
            )}
          </Flex>
        </QuoteStyles>
      </Container>
    </SectionStyles>
  );
};

export default PullOutQuote;
