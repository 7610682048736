import Container from '@components/container';
import styled from 'styled-components';
import { DefaultImage } from '@components/image/defaultImage';
import { useTheme } from '@aws-amplify/ui-react';
import { media } from '../../theme/mediaQueries';

/**
 * @typedef {import("@prismicio/client").Content.FullWidthImageSlice} FullWidthImageSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FullWidthImageSlice>} FullWidthImageProps
 * @param { FullWidthImageProps }
 */

interface SectionProps {
  readonly backgroundColor: string;
}

const SectionStyles = styled.section<SectionProps>`
  background-color: ${(props) => props.backgroundColor};
  padding-top: 73px;
  padding-bottom: 54px;
  overflow: hidden;

  .amplify-text {
    color: ${(props) => props.color};
  }

  ${media('medium')`
    padding-top: 64px;
    padding-bottom: 64px;
  `};
`;

const FullWidthImage = ({ slice }: any) => {
  const theme = useTheme();

  let backgroundColor = theme.tokens.colors.background.quaternary.value,
    color = theme.tokens.colors.primary.value;
  switch (slice.primary.background_colour?.toLowerCase()) {
    case 'light':
      backgroundColor = theme.tokens.colors.background.quaternary.value;
      break;

    case 'dark':
      backgroundColor = theme.tokens.colors.background.tertiary.value;
      color = theme.tokens.colors.white.value;
      break;
  }

  return (
    <SectionStyles backgroundColor={backgroundColor} color={color}>
      <Container>
        <DefaultImage
          src={slice.primary.image.url}
          alt={slice.primary.image.alt}
          caption={slice.primary.image.alt}
          width={slice.primary.image.dimensions.width}
          height={slice.primary.image.dimensions.height}
          background={backgroundColor}
          copyright={slice.primary.image.copyright}
          position="overlay-top"
        />
      </Container>
    </SectionStyles>
  );
};

export default FullWidthImage;
