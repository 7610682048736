import React from 'react';
import { PrismicLink } from '@prismicio/react';
import { useTheme } from '@aws-amplify/ui-react';
import HtmlText from '@components/text/htmlText';
import Container from '@components/container';
import styled from 'styled-components';
import { media } from '@theme/mediaQueries';

/**
 * @typedef {import("@prismicio/client").Content.FullWidthTextSlice} FullWidthTextSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FullWidthTextSlice>} FullWidthTextProps
 * @param { FullWidthTextProps }
 */

interface SectionProps {
  readonly backgroundColor: string;
}

const SectionStyles = styled.section<SectionProps>`
  background-color: ${(props) => props.backgroundColor};
  padding-top: 60px;
  padding-bottom: 27px;

  ${media('medium')`
    padding-bottom: 50px;
  `};
`;

const ContentStyles = styled.div`
  max-width: 816px;

  .subtitle {
    font-size: 1.275rem;
  }

  ${media('medium')`
  .subtitle {
    font-size: 1.375rem;
  }
`};
`;

const FullWidthText = ({ slice }: any) => {
  const theme = useTheme();

  let backgroundColor = theme.tokens.colors.background.primary.value,
    sliceBackground = slice.primary?.background_colour?.toLowerCase();
  switch (sliceBackground) {
    case 'light':
      backgroundColor = theme.tokens.colors.background.secondary.value;
      break;

    case 'white':
      backgroundColor = theme.tokens.colors.background.primary.value;
      break;
  }

  return (
    <SectionStyles theme={theme} backgroundColor={backgroundColor}>
      <Container>
        <ContentStyles>
          {slice.primary.title && (
            <HtmlText field={slice.primary.title} />
          )}
          {slice.primary.subtitle && (
            <HtmlText customClass="subtitle" field={slice.primary.subtitle} />
          )}
          {slice.primary.description && (
            <HtmlText
              field={slice.primary.description}
              backgroundColor={backgroundColor}
            />
          )}
          {slice.primary.cta ? (
            <PrismicLink field={slice.primary.cta}>My Link</PrismicLink>
          ) : (
            ''
          )}
        </ContentStyles>
      </Container>
    </SectionStyles>
  );
};

export default FullWidthText;
