import React from 'react';
import { useTheme } from '@aws-amplify/ui-react';
import Container from '@components/container';
import styled, { css } from 'styled-components';
import { media } from '@theme/mediaQueries';
import HtmlText from '@components/text/htmlText';

/**
 * @typedef {import("@prismicio/client").Content.EmbedSlice} EmbedSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<EmbedSlice>} EmbedProps
 * @param { EmbedProps }
 */

interface SectionProps {
  readonly backgroundColor: string;
  readonly variation: string;
}

interface InnerProps {
  readonly variation: string;
  readonly customIframeHeight: number;
}

const SectionStyles = styled.section<SectionProps>`
  padding-top: 61px;
  padding-bottom: 72px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};

  .amplify-heading {
    color: ${(props) => props.color};
  }

  ${media('medium')`
    padding-bottom: 110px;
  `};

  ${({ variation }: any) =>
    variation == 'videoOnTop' &&
    css`
      padding-bottom: 28px;

      ${media('medium')`
        padding-top: 71px;
        padding-bottom: 81px;
      `};
    `}

  ${({ variation }: any) =>
    variation == 'sideBySide' &&
    css`
      padding-top: 77px;
      padding-bottom: 34px;

      ${media('medium')`
        padding-top: 100px;
        padding-bottom: 122px;
      `};
    `}
`;

const InnerSectionStyles = styled.div<InnerProps>`
  iframe {
    width: 100%;
    height: 240px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 42px;
  }

  ${media('medium')`
    iframe {
      height: ${(props: { customIframeHeight: any }) =>
        props.customIframeHeight ? props.customIframeHeight + 'px' : '600px'};
    }

    h2 {
      margin-bottom: 44px;
    }
  `};

  ${({ variation }: any) =>
    variation == 'sideBySide' &&
    css`
      iframe {
        height: 437px;
      }

      .amplify-heading {
        margin-bottom: 16px;
      }

      p {
        margin-top: 0;
      }

      ${media('medium')`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10rem;

        .amplify-heading {
          margin-bottom: 10px;
        }

        .wwi-embed-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        iframe {
          height: 467px;
          max-width: 364px;
        }
      `};

      ${media('xl')`
        gap: 17.25rem;
      `};
    `}

  ${({ variation }: any) =>
    variation == 'videoOnTop' &&
    css`
      iframe {
        height: 299px;
      }

      ${media('medium')`
        iframe {
          height: 453px;
        }
      `};
    `}
`;

const Embed = ({ slice }: any) => {
  const { tokens } = useTheme();

  let backgroundColor = tokens.colors.background.primary.value,
    color = tokens.colors.primary.value,
    variation = slice.variation,
    customIframeHeight = slice.primary?.custom_iframe_height;

  switch (slice.primary?.background_colour?.toLowerCase()) {
    case 'light':
      backgroundColor = tokens.colors.background.secondary.value;
      break;

    case 'white':
      backgroundColor = tokens.colors.background.primary.value;
      break;

    case 'dark':
      backgroundColor = tokens.colors.background.tertiary.value;
      color = tokens.colors.white.value;
      break;
  }

  return (
    <SectionStyles
      backgroundColor={backgroundColor}
      color={color}
      variation={variation}
    >
      <Container>
        <InnerSectionStyles
          variation={variation}
          customIframeHeight={customIframeHeight}
        >
          <div className="wwi-embed-content">
            {slice.primary.title && <HtmlText field={slice.primary.title} />}
            {variation !== 'videoOnTop' && (
              <>
                {slice.primary.description && (
                  <HtmlText field={slice.primary.description} />
                )}
              </>
            )}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: slice.primary.embed_link.html }}
          />
          {variation === 'videoOnTop' && (
            <>
              {slice.primary.description && (
                <HtmlText field={slice.primary.description} />
              )}
            </>
          )}
        </InnerSectionStyles>
      </Container>
    </SectionStyles>
  );
};

export default Embed;
