import React from 'react';
import { Grid, useTheme } from '@aws-amplify/ui-react';
import styled from 'styled-components';
import HtmlText from '@components/text/htmlText';
import HtmlTitle from '@components/title/htmlTitle';
import Container from '@components/container';
import Cards from '@components/cards/cards';
import { media } from '@theme/mediaQueries';

/**
 * @typedef {import("@prismicio/client").Content.CardSlice} CardSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<CardSlice>} CardProps
 * @param { CardProps }
 */

const SectionStyles = styled.section`
  background-color: ${(props) =>
    props?.theme.tokens.colors.background.secondary.value};
  padding-top: 93px;
  padding-bottom: 177px;
  position: relative;
  overflow: hidden;

  ${media('medium')`
    padding-top: 72px;
    padding-bottom: 135px;
  `};
`;

const HeadingContentStyles = styled.div`
  margin-bottom: 36px;

  ${media('medium')`
    margin-bottom: 78px;
  `};
`;

const SectionIconStyles = styled.div`
  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    right: -110px;
    bottom: 60px;
    width: 462px;
    height: 178px;
    background-image: url('/../../images/bolt--large.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const Card = ({ slice }: any) => {
  const theme = useTheme();

  return (
    <SectionStyles theme={theme}>
      <Container>
        {slice.variation === 'pdfDownload' ? (
          <>
            <HeadingContentStyles>
              {slice?.items?.length === 1 ? (
                <HtmlText field={slice.primary.title} />
              ) : (
                <HtmlTitle type="heading3">
                  {slice?.items?.length} {slice.primary.plural_title}
                </HtmlTitle>
              )}
            </HeadingContentStyles>
            <Grid
              templateColumns={{
                base: '1fr',
                medium: '1fr 1fr',
                large: '1fr 1fr 1fr',
              }}
              gap="1.25rem"
            >
              {slice?.items?.map((item: any, i: number) => (
                <Cards
                  key={i}
                  cardLink={item.file}
                  cardTitle={item.title}
                  cardText={item.description}
                  variation={slice.variation}
                  cardHighlight={item.type}
                  cardTag={item.size}
                />
              ))}
            </Grid>
          </>
        ) : (
          <>{/*Other variation*/}</>
        )}
        <SectionIconStyles></SectionIconStyles>
      </Container>
    </SectionStyles>
  );
};

export default Card;
