import React from 'react';
import { PrismicRichText } from '@prismicio/react';

/**
 * @typedef {import("@prismicio/client").Content.AnchorSlice} AnchorSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<AnchorSlice>} AnchorProps
 * @param { AnchorProps }
 */
const Anchor = ({ slice }) => <span id={slice.primary.anchor_id}></span>;

export default Anchor;
