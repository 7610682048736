import { useTheme } from '@aws-amplify/ui-react';
import React from 'react';
import styled from 'styled-components';
import { PrismicLink } from '@prismicio/react';
import { linkResolver } from '../../prismicio';

interface ButtonProps {
  $hasIcon: any;
  $fullWidth: any;
}

const SecondaryButtonStyles = styled.div<ButtonProps>`
  a {
    background-color: ${(props) => props?.theme.tokens.colors.primary.value};
    color: ${(props) => props?.theme.tokens.colors.white.value} !important;
    padding: 10px 28px;
    border: none;
    border-radius: 3px;
    display: inline-block;

    &:visited {
      color: ${(props) => props?.theme.tokens.colors.white.value} !important;
    }

    &:hover {
      background-color: ${(props) => props?.theme.tokens.colors.white.value};
      color: ${(props) => props?.theme.tokens.colors.black.value} !important;
    }

    ${({ $hasIcon }: any) =>
      $hasIcon &&
      `
    position: relative;
    padding: 14px 46px 14px 16px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
      background-image: url('../../images/button-icon--white.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transition: transform ease-in-out 300ms;
    }

    &:hover {
      &::after {
        transform: translateY(-50%) translateX(7px);
        background-image: url('../../images/button-icon.svg');
      }
    }
  `}

    ${({ $fullWidth }: any) =>
      $fullWidth &&
      `
    width: 100%;
    border-radius: 0;
    text-align: center;
  `}
  }
`;

export default function SecondaryButton({
  children,
  fullWidth,
  hasIcon,
  ...props
}: any) {
  const theme = useTheme();

  return (
    <SecondaryButtonStyles
      theme={theme}
      $fullWidth={fullWidth}
      $hasIcon={hasIcon}
    >
      <PrismicLink {...props} linkResolver={linkResolver}>{children}</PrismicLink>
    </SecondaryButtonStyles>
  );
}
