import { useTheme, Flex } from '@aws-amplify/ui-react';
import { PrismicLink } from '@prismicio/react';
import styled from 'styled-components';
import Container from '@components/container';
import Image from 'next/image';
import { Rocks } from '@components/utilities/icons';
import { media } from '@theme/mediaQueries';

const SectionStyles = styled.section`
  position: relative;
  background-color: ${(props: any) =>
    props?.theme.tokens.colors.background.secondary.value};
  padding-top: 160px;
  padding-bottom: 37px;
  overflow: hidden;
`;

const SectionImageStyles = styled.div`
  position: absolute;
  top: 43px;
  left: 53%; // Visually centred
  transform: translateX(-50%);
  z-index: 0;
  width: 100%;

  ${media('medium')`
    max-width: 666px;
  `};
`;

const ContainerStyles = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${(props: any) =>
    props?.theme.tokens.colors.background.primary.value};
`;

const ContentStyles = styled.div`
  padding: 34px 0 62px;

  .vertical-spacer {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100px;
    flex: 1 0 33%;

    img {
      width: 100%;
      max-height: 93px;
    }

    &::before,
    &::after {
      position: absolute;
      background-color: ${(props: any) =>
        props?.theme.tokens.colors.primary.value};
      top: 0;
      width: 1px;
      height: 100%;
    }

    &--with-text {
      img {
        max-height: 150px;
      }
    }

    &:not(:last-child) {
      &::after {
        content: '';
        right: 0;
      }
    }
  }

  ${media('medium')`
    padding: 75px 0 74px;

    .vertical-spacer--with-text {
      &:first-child {
        &::before {
          content: '';
          left: 0;
        }
      }
    }
  `};
`;

const TextStyles = styled.div`
  display: flex;
  flex: 1 0 30%;
  width: 100%;
  position: relative;

  p {
    font-size: var(--font-size-promo);
    text-align: center;
    margin: 0;
  }

  ${media('medium')`
    padding-right: 21px;
    max-width: 190px;

    p {
      text-align: left;
    }
  `};
`;

// Updates the markup if there is not a link present otherwise the images do not show
const PartnerPromoItems = ({ children, item, slice, keyId }: any) => {
  let MarkupItem: any = PrismicLink;
  let fieldLink: any = item.link;

  if (!item.link.url) {
    MarkupItem = 'div';
    // fieldLink removes [object object] from DOM
    fieldLink = '';
  }

  return (
    <MarkupItem
      key={keyId}
      field={fieldLink}
      aria-label={item.name}
      className={
        slice.primary.promo_text
          ? 'vertical-spacer vertical-spacer--with-text'
          : 'vertical-spacer'
      }
    >
      {children}
    </MarkupItem>
  );
};

const PartnerPromo = ({ slice }: any) => {
  const theme = useTheme();

  return (
    <SectionStyles theme={theme}>
      <SectionImageStyles>
        <Rocks />
      </SectionImageStyles>
      <ContainerStyles theme={theme}>
        <Container>
          <ContentStyles theme={theme}>
            <Flex
              columnGap="0"
              alignItems="center"
              justifyContent="center"
              wrap={{ base: 'wrap', medium: 'nowrap' }}
            >
              {slice.primary.promo_text ? (
                <TextStyles theme={theme}>
                  <p>{slice.primary.promo_text}</p>
                </TextStyles>
              ) : (
                ''
              )}
              <Flex columnGap="0" alignItems="center">
                {slice?.items?.map((item: any, i: number) => (
                  <PartnerPromoItems key={i} keyId={i} item={item} slice={slice}>
                    <Image
                      src={item.logo.url}
                      alt={item.logo.alt || ''}
                      width={item.logo.dimensions.width}
                      height={item.logo.dimensions.height}
                    />
                  </PartnerPromoItems>
                ))}
              </Flex>
            </Flex>
          </ContentStyles>
        </Container>
      </ContainerStyles>
    </SectionStyles>
  );
};

export default PartnerPromo;
