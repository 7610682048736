import React from 'react';
import styled, { css } from 'styled-components';
import { Flex, useTheme } from '@aws-amplify/ui-react';
import Container from '@components/container';
import { media } from '@theme/mediaQueries';
import HtmlText from '@components/text/htmlText';
import Cards from '@components/cards/cards';

/**
 * @typedef {import("@prismicio/client").Content.MultiPromoSlice} MultiPromoSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<MultiPromoSlice>} MultiPromoProps
 * @param { MultiPromoProps }
 */
const SectionStyles = styled.section`
  background-color: ${(props: any) =>
    props?.theme.tokens.colors.background.secondary.value};
  padding-top: 53px;
  padding-bottom: 57px;

  .wwi-text-image {
    &__content {
      margin-top: 43px;

      a {
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
  }

  ${media('medium')`
    padding-top: 78px;
    padding-bottom: 124px;

    .wwi-text-image {
      &__content {
        margin-top: 0;

        a {
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
    }
  `};
`;

const MultiPromo = ({ slice }: any) => {
  const theme = useTheme();

  return (
    <SectionStyles theme={theme} className="wwi-text-image">
      <Container>
        <HtmlText field={slice.primary.title} />
        <HtmlText field={slice.primary.description} />
        <Flex direction="row" wrap="wrap" justifyContent="center" gap="1.25rem">
          {slice.items.map((el: any, key: number) => {
            // Only display the card if not broken.
            return !el.link.isBroken ? (
              <Cards
                key={key}
                cardTitle={el.title[0].text}
                cardLink={el.link}
                cardImage={el.image}
                cardText={el.description}
                variation="multiPromo"
                count={slice.items.length}
              />
            ) : (
              ''
            );
          })}
        </Flex>
      </Container>
    </SectionStyles>
  );
};

export default MultiPromo;
