import { Collection, Text, useTheme, View } from '@aws-amplify/ui-react';
import HtmlText from '@components/text/htmlText';
import Container from '@components/container';
import styled from 'styled-components';
import { media } from '@theme/mediaQueries';

const StatisticBlockStyles = styled.section`
  background-color: ${(props: any) =>
    props?.theme.tokens.colors.background.quaternary.value};
  color: ${(props: any) => props?.theme.tokens.colors.background.tertiary.value};
  padding: 51px 0 67px;

  .wwi-statistic-collection {
    &__title {
      color: ${(props: any) => props?.theme.tokens.colors.wwiGreen.value};
      font-size: 60px;
      margin-bottom: 19px;
      line-height: 1;
      font-weight: 700;
    }

    &__description {
      font-size: 16px;
      margin-bottom: 37px;
    }
  }

  .wwi-statistic-header {
    &__text {
      margin-top: 0;
      margin-bottom: 39px;
    }
  }

  ${media('medium')`
    .wwi-statistic-collection {
      &__description {
        font-size: 18px;
        margin-bottom: 16px;
      }
    }

    .wwi-statistic-header {
      &__text {
        margin-bottom: 50px;
      }
    }
  `}
`;

const Statistics = ({ slice }: any) => {
  const theme = useTheme();

  return (
    <StatisticBlockStyles theme={theme} className="text-center">
      <Container>
        <View as="div" className="wwi-statistic-header">
          <HtmlText
            customClass="wwi-statistic-header__title"
            field={slice.primary.title}
          />
          <HtmlText
            customClass="wwi-statistic-header__text"
            field={slice.primary.description}
          />
        </View>
        <Collection
          type="list"
          direction={{ base: 'column', medium: 'row' }}
          justifyContent="center"
          wrap="wrap"
          items={slice?.items}
          className="wwi-statistic-collection"
          gap={{ base: '2.375rem', medium: '2rem', xl: '4.5625rem' }}
        >
          {(item: any, index) => (
            <View as="div" key={index}>
              <Text className="wwi-statistic-collection__title">
                {item.data}
              </Text>
              <HtmlText
                className="wwi-statistic-collection__description"
                field={item.data_description}
              />
            </View>
          )}
        </Collection>
      </Container>
    </StatisticBlockStyles>
  );
};

export default Statistics;
