import React from 'react';
import { useTheme, Flex } from '@aws-amplify/ui-react';
import { PrismicLink } from '@prismicio/react';
import Image from 'next/image';
import Container from '@components/container';
import styled from 'styled-components';
import { Linkedin, Twitter, Facebook, Link } from '@components/utilities/icons';

/**
 * @typedef {import("@prismicio/client").Content.SocialSharingSlice} SocialSharingSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<SocialSharingSlice>} SocialSharingProps
 * @param { SocialSharingProps }
 */

const SectionStyles = styled.section`
  background-color: ${(props) =>
    props?.theme.tokens.colors.background.secondary.value};
  padding: 32px 0;
`;

const LinkStyles = styled.div`
  width: 70px;
  height: 70px;
  background-color: ${(props) => props?.theme.tokens.colors.primary.value};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .wwi-icon {
    &--facebook {
      max-width: 14px;
    }

    &--twitter {
      max-width: 26px;
    }

    &--linkedin {
      max-width: 70px;
    }

    &--link {
      max-width: 32px;
    }
  }

  a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const SocialSharing = ({ slice }: any) => {
  const theme = useTheme();
  const updateSocialIcon = (platform: any) => {
    switch (platform) {
      case 'twitter':
        return <Twitter />;
      case 'linkedin':
        return <Linkedin />;
      case 'facebook':
        return <Facebook />;
      case 'email':
        return <Link />;
    }
  };

  return (
    <SectionStyles theme={theme}>
      <Container>
        <Flex columnGap="1.25rem" alignItems="center">
          {slice?.items?.map((item: any, i: number) => (
            <LinkStyles key={i} theme={theme}>
              <div>
                <PrismicLink key={i} field={item.link}></PrismicLink>
                {item.platform ? (
                  <>{updateSocialIcon(item.platform)}</>
                ) : (
                  <Image
                    src={item.logo.url}
                    alt={item.alt}
                    width={30}
                    height={30}
                  />
                )}
              </div>
            </LinkStyles>
          ))}
        </Flex>
      </Container>
    </SectionStyles>
  );
};

export default SocialSharing;
