// Code generated by Slice Machine. DO NOT EDIT.

import Anchor from './Anchor';
import Card from './Card';
import Embed from './Embed';
import FullWidthImage from './FullWidthImage';
import FullWidthPromo from './FullWidthPromo';
import FullWidthText from './FullWidthText';
import Links from './Links';
import Listing from './Listing';
import MenuGroup from './MenuGroup';
import MenuItem from './MenuItem';
import MultiPromo from './MultiPromo';
import OembedLink from './OembedLink';
import PartnerPromo from './PartnerPromo';
import PullOutQuote from './PullOutQuote';
import SocialSharing from './SocialSharing';
import Statistics from './Statistics';
import TextAndImage from './TextAndImage';
import Wayfinder from './Wayfinder';

export {
	Anchor,
	Card,
	Embed,
	FullWidthImage,
	FullWidthPromo,
	FullWidthText,
	Links,
	Listing,
	MenuGroup,
	MenuItem,
	MultiPromo,
	OembedLink,
	PartnerPromo,
	PullOutQuote,
	SocialSharing,
	Statistics,
	TextAndImage,
	Wayfinder,
};

export const components = {
	anchor: Anchor,
	card: Card,
	embed: Embed,
	full_width_image: FullWidthImage,
	full_width_promo: FullWidthPromo,
	full_width_text: FullWidthText,
	links: Links,
	listing: Listing,
	footer_item: MenuGroup,
	terms_and_conditions_menu_item: MenuItem,
	multi_promo: MultiPromo,
	oembedLink: OembedLink,
	partner_promo: PartnerPromo,
	pull_out_quote: PullOutQuote,
	social_sharing: SocialSharing,
	statistics: Statistics,
	text_and_image: TextAndImage,
	wayfinder: Wayfinder,
};
