import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Flex, useTheme } from '@aws-amplify/ui-react';
import Container from '@components/container';
import { media } from '@theme/mediaQueries';
import HtmlText from '@components/text/htmlText';
import { createClient } from '../../prismicio';
import * as prismic from '@prismicio/client';
import Cards from '@components/cards/cards';
import SecondaryButton from '@components/buttons/secondary';

/**
 * @typedef {import("@prismicio/client").Content.ListingSlice} ListingSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ListingSlice>} ListingProps
 * @param { ListingProps }
 */
const client = createClient();
const MAX_ELEMENTS = 6;

const SectionStyles = styled.section`
  background-color: ${(props) =>
    props?.theme.tokens.colors.background.secondary.value};
  padding: 93px 0 278px;
  position: relative;
  overflow: hidden;

  .wwi-text-image {
    &__content {
      margin-top: 43px;

      a {
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
  }

  ${media('medium')`
    padding: 88px 0 184px;

    .wwi-text-image {
      &__content {
        margin-top: 0;

        a {
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
    }
  `};
`;

const ListingContentStyles = styled.div`
  margin-bottom: 35px;

  .wwi-listing-text {
    max-width: 746px;
  }

  ${media('medium')`
    margin-bottom: 40px;
  `};
`;

const SectionIconStyles = styled.div`
  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    right: -110px;
    bottom: 30px;
    width: 462px;
    height: 178px;
    background-image: url('/../../images/bolt--large.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const Listing = ({ slice }: any) => {
  const theme = useTheme();
  const [results, setResults]: Array<any> = useState();
  // Page number start in 1 in Prismic.
  const [pageNumber, setPageNumber]: Array<any> = useState(1);
  const [isLastPage, setIsLastPage]: Array<any> = useState(false);

  // Set page size
  const needsPager: boolean = slice.primary.nelements > MAX_ELEMENTS;
  const pageSize: number = needsPager ? MAX_ELEMENTS : slice.primary.nelements;

  const types: Array<any> = [];
  // Set types array
  let isProduct = false;
  slice.items.map((el: any) => {
    // If content type null, ignore the element.
    if (!el.content_type) {
      return;
    }
    types.push(el.content_type.toLowerCase());
    if (el.content_type.toLowerCase() === 'product') {
      isProduct = true;
    }
  });

  let categories: Array<string> = [];
  if (slice.primary?.categories?.length > 0) {
    categories = slice.primary.categories.split(',') || [];
  }

  useEffect(() => {
    const getItems = async () => {
      const response = await client.get({
        predicates: [
          prismic.predicate.any('document.type', types),
          prismic.predicate.any('document.tags', categories),
        ],
        pageSize: pageSize < 1 ? MAX_ELEMENTS : pageSize,
        page: pageNumber,
      });

      setResults(response?.results);
      if (response.total_pages == pageNumber) {
        setIsLastPage(true);
      }
    };
    if (types.length > 0) {
      getItems();
    }
  }, [pageNumber]);

  const updateName = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <SectionStyles theme={theme} className="wwi-text-image">
      <Container>
        <ListingContentStyles>
          <HtmlText field={slice.primary.title} />
          <HtmlText
            customClass="wwi-listing-text"
            field={slice.primary.description}
          />
          <SecondaryButton field={slice.primary.button_link}>
            {slice.primary.button_text}
          </SecondaryButton>
        </ListingContentStyles>
        <Flex
          direction="row"
          alignItems="flex-start"
          wrap="wrap"
          justifyContent="center"
          gap="1.25rem"
        >
          {results &&
            results.map((el: any) => (
              <Cards
                key={el.id}
                cardTitle={el.data.hasOwnProperty('title') && el.data.title.length > 0 ? el.data.title[0].text : ''}
                cardLink={el.url}
                cardDataLink={el.data.link}
                cardImage={el.data.image}
                cardHighlight={el.data.price || el.type}
                cardTag={el.data.tag || null}
                cardText={el.data.description}
                variation={el.type}
              />
            ))}
        </Flex>
        {needsPager && !isLastPage && (
          <Button onClick={updateName}>
            <p>Next page</p>
          </Button>
        )}
        {/* Should only show SectionIconStyles if no product types are displayed */}
        {!isProduct && <SectionIconStyles></SectionIconStyles>}
      </Container>
    </SectionStyles>
  );
};

export default Listing;
