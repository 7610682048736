import React, { useState } from 'react';
import HtmlText from '@components/text/htmlText';
import Container from '@components/container';
import styled from 'styled-components';
import { Flex, SelectField, useTheme } from '@aws-amplify/ui-react';
import PrimaryButton from '@components/buttons/primary';
import { media } from '../../theme/mediaQueries';

/**
 * @typedef {import("@prismicio/client").Content.WayfinderSlice} WayfinderSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<WayfinderSlice>} WayfinderProps
 * @param { WayfinderProps }
 */

interface Link {
  link_type: string;
  url: string;
}

interface Item {
  action: string;
  destination: Link;
}

interface SectionProps {
  readonly backgroundColor: string;
}

const SectionStyles = styled.section<SectionProps>`
  background-color: ${(props) => props.backgroundColor};
  padding: 32px 0 40px;

  .amplify-heading {
    color: ${(props) => props.color};
  }

  ${media('medium')`
    padding: 60px 0;
  `};
`;

const SelectFieldStyles = styled(SelectField)`
  background-color: ${(props) => props?.theme.tokens.colors.white.value};
  margin: 16px 0 20px;

  select {
    border-color: ${(props) => props?.theme.tokens.colors.primary.value};
    padding: 13px 48px 13px 16px;
  }

  ${media('medium')`
    margin: 0 20px 0 30px;
  `};
`;

const Wayfinder = ({ slice }: any) => {
  const [destination, setDestination] = useState(slice.items[0].destination),
    theme = useTheme();

  const onSelectionChange = (key: any) => {
    setDestination(slice.items[key].destination);
  };

  let backgroundColor = theme.tokens.colors.background.quaternary.value,
    color = theme.tokens.colors.primary.value;
  switch (slice.primary?.background_colour?.toLowerCase()) {
    case 'light':
      backgroundColor = theme.tokens.colors.background.quaternary.value;
      break;

    case 'dark':
      backgroundColor = theme.tokens.colors.background.tertiary.value;
      color = theme.tokens.colors.white.value;
      break;
  }

  return (
    <SectionStyles backgroundColor={backgroundColor} color={color}>
      <Container>
        <Flex
          direction={{ base: 'column', medium: 'row' }}
          alignItems={{ base: 'flex-start', medium: 'center' }}
          gap="0"
        >
          {slice.primary.description ? (
            <HtmlText customClass="mb-0" field={slice.primary.description} />
          ) : (
            ''
          )}
          <SelectFieldStyles
            theme={theme}
            label="Action selector"
            labelHidden
            descriptiveText={slice.primary.description[0].text || ''}
            onChange={(e) => onSelectionChange(e.target.value)}
            borderRadius={theme.tokens.components.fieldcontrol.borderRadius}
          >
            {slice.items.map((item: Item, i: any) => (
              <option key={i} value={i}>
                {item.action}
              </option>
            ))}
          </SelectFieldStyles>
          <PrimaryButton hasIcon field={destination}>
            {"Let's go"}
          </PrimaryButton>
        </Flex>
      </Container>
    </SectionStyles>
  );
};

export default Wayfinder;
